import { getAuth, signOut } from "firebase/auth";

const auth = getAuth();

export function logout() {
  signOut(auth)
    .then(() => {
      // console.log("로그아웃 성공");
      // 세션 스토리지에서 uid 제거
      sessionStorage.removeItem("uid");
      window.location.href = "/login";
    })
    .catch((error) => {
      // console.log("로그아웃 실패:", error);
    });
}

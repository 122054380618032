/* eslint-disable react-hooks/rules-of-hooks */
import { signInWithEmailAndPassword } from "firebase/auth";

export function login(auth, email, password) {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // 로그인 성공
      const user = userCredential.user;
      // console.log("로그인 성공:", user);
      sessionStorage.setItem("uid", user.uid);
      window.location.href = "/admin";
    })
    .catch((error) => {
      // 로그인 실패
      const errorCode = error.code;
      // const errorMessage = error.message;
      // console.log(`로그인 실패: ${errorCode} - ${errorMessage}`);
      if (errorCode === "auth/invalid-login-credentials") {
        alert("유효하지 않은 정보입니다.");
      }
      if (errorCode === "auth/too-many-requests") {
        alert("너무 많은 요청으로 계정이 제한됩니다. 잠시 후 시도해주세요");
      }
    });
}

import React, { useState } from "react";
import { createPostData } from "../hooks/createPostData";
import { Good } from "./Good";

function Dashboard() {
  const [good, setGood] = useState([]);
  const [isModal, setIsModal] = useState(false);

  const handleCheckboxChange = (event) => {
    const label = event.target.nextElementSibling.innerText; // 라벨 텍스트 가져오기
    const isChecked = event.target.checked;

    if (isChecked) {
      setGood((prevGood) => [...prevGood, label]);
    } else {
      setGood((prevGood) => prevGood.filter((item) => item !== label));
    }
  };

  // 희망 상담 일자 및 시간을 저장하는 state
  const [consultationDate, setConsultationDate] = useState("");
  const [consultationTime, setConsultationTime] = useState("9:00-10:59");

  // 개인정보 입력을 저장하는 state
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    location: "",
    occupation: "",
  });

  // 개인정보 수집 동의 체크박스 state
  const [dataCollectionAgreement, setDataCollectionAgreement] = useState(false);

  // 희망 상담 날짜 변경 핸들러
  const handleConsultationDateChange = (event) => {
    setConsultationDate(event.target.value);
    // console.log(event.target.value);
  };

  // 희망 상담 시간 변경 핸들러
  const handleConsultationTimeChange = (event) => {
    setConsultationTime(event.target.value);
  };

  // 개인정보 입력 변경 핸들러
  const handleFormInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //전화번호 입력 폼
  const handleFormInputChange2 = (e) => {
    const { name, value } = e.target;
    let formattedValue = value.replace(/\D/g, ""); // 숫자만 남김

    if (formattedValue.length > 3 && formattedValue.length <= 7) {
      formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(
        3
      )}`;
    } else if (formattedValue.length > 7) {
      formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(
        3,
        7
      )}-${formattedValue.slice(7, 11)}`;
    }

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  // 개인정보 수집 동의 체크박스 변경 핸들러
  const handleDataCollectionAgreementChange = (event) => {
    setDataCollectionAgreement(event.target.checked);
  };

  // 제출 버튼 클릭 핸들러 (여기서 상태 값을 활용하여 데이터를 전송하거나 처리할 수 있습니다)
  const handleSubmit = () => {
    if (good.length === 0) {
      alert("광고 관심 주제를 하나 이상 선택해 주세요.");
      return;
    }

    if (!consultationDate || !consultationTime) {
      alert("희망 상담 일자와 시간을 선택해 주세요.");
      return;
    }

    if (
      !formData.name ||
      !formData.phone ||
      !formData.location ||
      formData.phone.length < 13
    ) {
      alert("이름, 전화번호, 지역을 모두 입력해 주세요.");
      return;
    }

    if (!dataCollectionAgreement) {
      alert("개인정보 수집에 동의 부탁드립니다.");
      return;
    }

    createPostData(
      good,
      consultationDate,
      consultationTime,
      formData.name,
      formData.phone,
      formData.location,
      formData.occupation
    );
    alert("감사합니다.");
  };

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function generateTimeSlots() {
    const timeSlots = [];
    for (let i = 9; i <= 17; i += 2) {
      timeSlots.push(
        <option key={i} value={`${i}:00-${i + 2}:00`}>
          {`${i}:00 - ${i + 2}:00`}
        </option>
      );
    }
    return timeSlots;
  }

  return (
    <div className="Dashboard">
      <div className="page-container">
        {/* 페이지 컨테이너 */}
        {/* <h1>광고성 DB 수집 페이지</h1> */}
        {/* 첫번째 섹션: 항목 체크박스 */}
        <section className="section">
          <h2>관심 주제</h2>
          <ul>
            <li>
              <input
                type="checkbox"
                id="chk1"
                onChange={handleCheckboxChange}
              />
              <label htmlFor="chk1">건강보험</label>
            </li>
            <li>
              <input
                type="checkbox"
                id="chk2"
                onChange={handleCheckboxChange}
              />
              <label htmlFor="chk2">치매/간병보험</label>
            </li>
            <li>
              <input
                type="checkbox"
                id="chk3"
                onChange={handleCheckboxChange}
              />
              <label htmlFor="chk3">실손의료보험</label>
            </li>
            <li>
              <input
                type="checkbox"
                id="chk4"
                onChange={handleCheckboxChange}
              />
              <label htmlFor="chk4">저축(가계재무 관리)</label>
            </li>
            <li>
              <input
                type="checkbox"
                id="chk5"
                onChange={handleCheckboxChange}
              />
              <label htmlFor="chk5">투자(펀드/주식 변액 관리)</label>
            </li>
            <li>
              <input
                type="checkbox"
                id="chk6"
                onChange={handleCheckboxChange}
              />
              <label htmlFor="chk6">상속/증여</label>
            </li>
            <li>
              <input
                type="checkbox"
                id="chk7"
                onChange={handleCheckboxChange}
              />
              <label htmlFor="chk7">절세</label>
            </li>
          </ul>
          {/* <div>
              선택된 항목: {good.join(', ')}
            </div>    */}
        </section>
        {/* 두번째 섹션: 날짜 및 시간 선택 */}
        <section className="section">
          <h2>희망 상담 일자 및 시간</h2>
          <div className="input-group">
            <div>
              <label htmlFor="consultation-date">희망 상담 날짜:</label>
              <input
                type="date"
                id="consultation-date"
                name="consultation-date"
                min={getCurrentDate()}
                value={consultationDate}
                onChange={handleConsultationDateChange}
              />
            </div>
            <div>
              <label htmlFor="consultation-time">희망 상담 시간:</label>
              <select
                id="consultation-time"
                name="consultation-time"
                value={consultationTime}
                onChange={handleConsultationTimeChange}
              >
                {generateTimeSlots()}
              </select>
            </div>
          </div>
        </section>
        {/* 세번째 섹션: 개인정보 입력 */}
        <section className="section">
          <h2>개인정보 입력</h2>
          <div className="input-group">
            <div>
              <label htmlFor="name">
                <input
                  placeholder="이름"
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleFormInputChange}
                />
              </label>
            </div>
            <div>
              <label htmlFor="phone">
                <input
                  placeholder="전화번호"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={(handleFormInputChange, handleFormInputChange2)}
                />
              </label>
            </div>

            <div>
              <label htmlFor="location" style={{ zIndex: "1000" }}>
                <input
                  placeholder="지역"
                  type="text"
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleFormInputChange}
                />
              </label>
            </div>
            <div>
              <label htmlFor="occupation" style={{ zIndex: "1000" }}>
                <input
                  placeholder="직업(선택사항입니다.)"
                  type="text"
                  id="occupation"
                  name="occupation"
                  value={formData.occupation}
                  onChange={handleFormInputChange}
                />
              </label>
            </div>
          </div>
        </section>
        {/* 개인정보 수집 동의 체크박스 */}
        <div className="section">
          <input
            type="checkbox"
            id="data-collection-agreement"
            name="data-collection-agreement"
            checked={dataCollectionAgreement}
            onChange={handleDataCollectionAgreementChange}
          />
          <label htmlFor="data-collection-agreement" style={{ zIndex: "1000" }}>
            개인정보 수집에 동의합니다.
          </label>
        </div>
        {/* 제출 버튼 */}
        <button type="submit" className="submit-button" onClick={handleSubmit}>
          제출
        </button>
      </div>
      <div className="footer">
        <div className="section2">
          <h2>반트</h2>
          <p>
            대표자 : 김인태 | 사업자등록번호 : 170-16-01783 | 대표번호 :
            010-5424-6974
          </p>
          <button onClick={() => setIsModal(!isModal)}>개인정보처리방침</button>
        </div>
      </div>
      {isModal && (
        <div
          className={`modal-backdrop ${isModal ? "active" : ""}`}
          onClick={() => setIsModal(false)}
        >
          <p className="title">
            개인정보처리방침
            <span className="pop-esc">x</span>
          </p>
          <Good />
        </div>
      )}
    </div>
  );
}

export default Dashboard;

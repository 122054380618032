import { collection, addDoc, Timestamp } from "firebase/firestore";
import { db } from "../firebase/firestore";

export async function createPostData(
  content,
  date,
  time,
  name,
  phone,
  region,
  job
) {
  try {
    const createdTime = Timestamp.fromDate(new Date());
    const docRef = await addDoc(collection(db, "posts"), {
      content: content,
      date: date,
      time: time,
      name: name,
      phone: phone,
      region: region,
      job: job,
      createdTime: createdTime,
    });
    // console.log("데이터가 생성되었습니다.: ", docRef.id);
    window.location.reload(); // 데이터 생성 후 페이지 리로딩
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

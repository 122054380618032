import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export function excelDownload(postData) {
  const excelFileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const excelFileExtension = ".xlsx";
  const excelFileName = `db 데이터 열람`;

  const ws = XLSX.utils.aoa_to_sheet([
    ["관심 주제", "상담 날짜", "상담 시간", "이름", "전화번호", "지역", "직업"],
  ]);

  postData.map((item) => {
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          item.content.join(" "),
          item.date,
          item.time,
          item.name,
          item.phone,
          item.region,
          item.job,
        ],
      ],
      { origin: -1 }
    );
    ws["!cols"] = [
      { wpx: 150 },
      { wpx: 70 },
      { wpx: 70 },
      { wpx: 50 },
      { wpx: 100 },
      { wpx: 50 },
      { wpx: 50 },
    ];
    return false;
  });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelButter = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const excelFile = new Blob([excelButter], { type: excelFileType });
  saveAs(excelFile, excelFileName + excelFileExtension);
}

import React, { useEffect, useState } from "react";
import { getPostData } from "../hooks/getPostData";
import { excelDownload } from "../context/utils/excelDown";
import { logout } from "../hooks/logout";

export default function Admin() {
  const [postData, setPostData] = useState([]);

  useEffect(() => {
    if (!sessionStorage.getItem("uid")) {
      window.location.href = "/login";
    }

    // getPostData 함수를 호출하여 데이터를 가져오고 state에 저장
    const fetchData = async () => {
      try {
        const result = await getPostData();
        setPostData(result);
        // console.log(result);
      } catch (error) {
        console.error("데이터 가져오기 오류:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="Admin">
      <div className="section">
        <h1>DB 데이터 열람 페이지</h1>
        <button onClick={() => logout()}>로그아웃</button>
        <button onClick={() => excelDownload(postData)}>엑셀다운</button>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>관심 주제</th>
              <th>상담 날짜</th>
              <th>상담 시간</th>
              <th>이름</th>
              <th>전화번호</th>
              <th>지역</th>
              <th>직업</th>
            </tr>
          </thead>
          <tbody>
            {postData.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.content}</td>
                <td>{item.date}</td>
                <td>{item.time}</td>
                <td>{item.name}</td>
                <td>{item.phone}</td>
                <td>{item.region}</td>
                <td>{item.job}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

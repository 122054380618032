import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import "./App.css"

export default function App() {


  return(
    <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard/>}/> 
            <Route path="/login" element={<Login/>}/>
            <Route path="/admin" element={<Admin/>}/>
          </Routes>
        </BrowserRouter>      
    </div>
  )
}
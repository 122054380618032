export function Good() {
  return (
    <p className="pop-content">
      <b>[개인정보 수집 및 이용안내]</b>
      <br />
      반트는 상담신청 및 상품 소개를 위해 고객님의 개인정보 수집, 이용 및 제공에
      대한 동의를 받고 있습니다.
      <br />
      당사 및 당사 업무수탁자는 「개인정보보호법」, 「정보통신망 이용촉진 및
      정보 보호 등에 관한 법률」에 따라 귀하의 개인정보를 다음과 같이
      수집·이용하고자 합니다.
      <br />
      <br />
      - 개인정보 수집주체 : 반트
      <br />
      - 개인정보 수집항목 : 고객명, 연락처, 거주지역, 직업 <br />
      - 개인정보 수집,이용목적 : 상품/서비스 소개 및 상담 (전화,문자) <br />
      - 개인정보 보유,이용기간 : 수집일로부터 3년(고객 동의 철회시 지체없이
      파기)
      <br />
      <br />
      <b>[개인정보 취급위탁]</b>
      <br />
      당사는 「개인정보보호법」, 「정보통신망 이용촉진 및 정보 보호 등에 관한
      법률」에 따라 귀하의 개인정보를 다음과 같이 제3자에게 제공하고자 합니다.
      <br />
      - 개인정보를 제공받는 자 : 당사와 모집위탁계약을 체결한 자<br />
      - 개인정보를 제공받는 자의 이용목적 : 상품/서비스 소개 및 상담
      <br />
      - 제공할 개인정보의 내용 : 연락처
      <br />
      - 제공받는 자의 개인정보 보유·이용 기간 : 동의일로부터 3년
      <br />
    </p>
  );
}

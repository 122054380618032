import React, { useEffect, useState } from "react";
import { auth } from "../firebase/firestore";
import { login } from "../hooks/login";
import { useNavigate } from "react-router-dom";

export default function Login() {
  // 아이디와 비밀번호를 저장하는 state
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const Navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("uid")) {
      Navigate("/admin");
    }
  }, []);

  // 아이디 입력 변경 핸들러
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  // 비밀번호 입력 변경 핸들러
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // 로그인 버튼 클릭 핸들러
  const handleLogin = async () => {
    login(auth, username, password);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault(); // 폼의 기본 제출 동작을 중지
    handleLogin();
  };

  return (
    <div className="Login">
      <div className="login-container">
        <h1>로그인</h1>
        <form onSubmit={handleFormSubmit}>
          {/* 아이디 입력 섹션 */}
          <div className="input-group">
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              placeholder="이메일 아이디"
              onChange={handleUsernameChange}
            />
          </div>

          {/* 비밀번호 입력 섹션 */}
          <div className="input-group">
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              placeholder="비밀번호"
              onChange={handlePasswordChange}
            />
          </div>

          {/* 로그인 버튼 */}
          <button className="login-button" type="submit">
            로그인
          </button>
        </form>
      </div>
    </div>
  );
}
